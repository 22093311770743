<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="500"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          ref="weixin"
          label="微信/手机号"
          prop="weixin"
          v-bind="formItemLayout"
      >
        <a-input
            v-model="form.weixin"
            allow-clear
            placeholder="请输入微信/手机号"
        />
      </a-form-model-item>
      <a-form-model-item
          ref="title"
          label="名称/描述"
          prop="title"
          v-bind="formItemLayout"
      >
        <a-input v-model="form.title" placeholder="请输入名称/描述"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "EditContactModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
      },
      mode: 'add',
      title: "",
      visible: false,
      weixinList: [],
      index: 0,
      confirmLoading: false,
      form: {},
      rules: {
        weixin: [
          {
            required: true,
            message: "请输入微信/手机号",
            trigger: "blur",
          },
        ],
        title: [{required: true, message: "请输入名称/描述"}]
      },
    };
  },
  methods: {
    showModal(mode, index, weixinList) {
      this.mode = mode || 'add';
      this.weixinList = [].concat(weixinList);
      this.index = index;
      if (this.mode == 'add') {
        this.title = "增加业务微信";
        this.form = {
          weixin: '', title: ''
        };
      } else {
        this.title = "修改业务微信";
        this.form = {
          ...weixinList[index],
        };
      }
      this.visible = true;
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.visible = false;
          if (this.mode == 'edit') {
            this.weixinList[this.index] = this.form
          }else{
            this.weixinList.push(this.form)
          }
          this.$emit("success", this.weixinList);
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>

</style>
